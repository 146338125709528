import axios, { type AxiosRequestHeaders } from "axios";
import { fetchToken, forceRefreshToken } from "../auth/firebase";
import { getCookie } from "cookies-next";

const isSSR = typeof window === "undefined";

type ApiResponse<T = {}> = {
  data: T;
  errorCode: string;
  errorMsg: string;
};

export class ApiError extends Error {
  code: string;
  msg: string;

  constructor(code: string, msg: string) {
    super(msg);
    this.name = "ApiError";
    this.code = code;
    this.msg = msg;
  }
}

const instance = axios.create({
  baseURL: process.env.apiBaseUrl
});

if (!isSSR) {
  // only add "Authorization" header when calling from client

  instance.interceptors.request.use(
    async (config) => {
      if (!config.headers) {
        config.headers = {} as AxiosRequestHeaders;
      }

      const token = await fetchToken();
      config.headers.ga_cid = getCookie("_ga")?.replace("GA1.1.", "") || "";
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (res) => {
      if (res.data.errorCode !== "000000") {
        return Promise.reject(
          new ApiError(res.data.errorCode, res.data.errorMsg)
        );
      }
      return res;
    },
    async (error) => {
      const originalRequest = error.config;
      const { data } = error.response || {};

      if (originalRequest && !originalRequest._retry && error.response) {
        const { status } = error.response || {};

        if (status === 401) {
          originalRequest._retry = true;
          await forceRefreshToken();
          instance(originalRequest);
        }
      }

      return Promise.reject(new ApiError(data?.errorCode, data?.errorMsg));
    }
  );
}

export async function get<T>(...params: Parameters<typeof instance.get>) {
  const res = await instance.get<ApiResponse<T>>(...params);
  return res.data.data;
}

export async function post<T>(...params: Parameters<typeof instance.post>) {
  const res = await instance.post<ApiResponse<T>>(...params);
  return res.data.data;
}

export async function put<T>(...params: Parameters<typeof instance.put>) {
  const res = await instance.put<ApiResponse<T>>(...params);
  return res.data.data;
}

export async function del<T>(...params: Parameters<typeof instance.delete>) {
  const res = await instance.delete<ApiResponse<T>>(...params);
  return res.data.data;
}

export async function patch<T>(...params: Parameters<typeof instance.patch>) {
  const res = await instance.patch<ApiResponse<T>>(...params);
  return res.data.data;
}
