export async function delay(ms: number) {
  return new Promise(function (resolve) {
    window.setTimeout(resolve, ms);
  });
}

// Get text content from react node.
// example: getReactNodeText(<div>Hello <a href="https://hyperbooth.ai">HyperBooth</a></div>) => "Hello HyperBooth"
export const getReactNodeText = (node: React.ReactNode): string => {
  if (node == null) return "";

  switch (typeof node) {
    case "string":
    case "number":
      return node.toString();

    case "boolean":
      return "";

    case "object": {
      if (node instanceof Array) return node.map(getReactNodeText).join("");

      if ("props" in node) return getReactNodeText(node.props.children);
    }

    default:
      console.warn("Unresolved `node` of type:", typeof node, node);
      return "";
  }
};
