// styled-components
import { reset } from "@hypergai-frontend-mvp/shared-lib";
import { css, createGlobalStyle } from "styled-components";

const styles = css`
  html {
    font-size: 10px;
    //background-color: ${({ theme }) => theme.colors.darkGrey};
  }
  body {
    background-color: #0f0f0f;
    width: 100%;
    height: 100svh;
    color: white;
    font-size: 1.6rem;
    line-height: 2rem;

    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }

  button:not(:disabled) {
    cursor: pointer;
  }

  hr {
    border: 0;
    border-top: 1px solid;
    opacity: 0.25;
  }

  input {
    margin: 0;
  }

  img,
  svg {
    vertical-align: middle;
  }

  a {
    text-decoration: none;
  }

  .margin-left-2 {
    margin-left: 2rem;
  }

  .margin-left-1 {
    margin-left: 0.8rem;
  }

  .margin-right-1 {
    margin-right: 0.8rem;
  }

  // For Sliders
  .horizontal-slider {
    width: 20rem;
    height: 0.8rem;
    //background-color: ${({ theme }) => theme.colors.orange};
    border-radius: 8px;
  }
  .slider-thumb {
    cursor: pointer;
    border: 10px solid #ffffff;
    border-radius: 100%;
    position: absolute;
    top: -6px;
  }

  .slider-track-0 {
    background-color: ${({ theme }) => theme.colors.orange};
    height: 0.8rem;
    border-radius: 8px;
  }

  .slider-track-1 {
    background-color: ${({ theme }) => theme.colors.white20};
    height: 0.8rem;
    border-radius: 8px;
  }

  .hgg-md-show {
    display: none;

    @media (min-width: ${({ theme }) => theme.screen.md}) {
      display: block;
    }
  }

  .hgg-md-hide {
    display: block;

    @media (min-width: ${({ theme }) => theme.screen.md}) {
      display: none;
    }
  }
  .hgg-lg-show {
    display: none;

    @media (min-width: ${({ theme }) => theme.screen.lg}) {
      display: block;
    }
  }

  .hgg-lg-hide {
    display: block;

    @media (min-width: ${({ theme }) => theme.screen.lg}) {
      display: none;
    }
  }

  //Legal pages style sheet

  .awlist1 {
    list-style: none;
    counter-reset: awlistcounter1_0;
  }

  .awlist1 > li:before {
    content: counter(awlistcounter1_0) ".";
    counter-increment: awlistcounter1_0;
  }

  .awlist2 {
    list-style: none;
    counter-reset: awlistcounter1_1;

    a {
      color: white;
    }
  }

  .awlist2 > li:before {
    content: counter(awlistcounter1_0) "."
      counter(awlistcounter1_1, lower-latin) ".";
    counter-increment: awlistcounter1_1;
  }

  .awlist3 {
    list-style: none;
    counter-reset: awlistcounter1_2;
  }

  .awlist3 > li:before {
    content: "(" counter(awlistcounter1_2, lower-latin) ")";
    counter-increment: awlistcounter1_2;
  }

  .awlist4 {
    list-style: none;
    counter-reset: awlistcounter1_3;
  }

  .awlist4 > li:before {
    content: "(" counter(awlistcounter1_3, lower-roman) ")";
    counter-increment: awlistcounter1_3;
  }

  .awlist5 {
    list-style: none;
    counter-reset: awlistcounter1_4;
  }

  .awlist5 > li:before {
    content: "(" counter(awlistcounter1_4) ")";
    counter-increment: awlistcounter1_4;
  }

  .awlist6 {
    list-style: none;
    counter-reset: awlistcounter1_0 3;
  }

  .awlist6 > li:before {
    content: counter(awlistcounter1_0) ".";
    counter-increment: awlistcounter1_0;
  }

  .awlist7 {
    list-style: none;
    counter-reset: awlistcounter1_0 4;
  }

  .awlist7 > li:before {
    content: counter(awlistcounter1_0) ".";
    counter-increment: awlistcounter1_0;
  }
`;

const GlobalStyles = createGlobalStyle`
  ${reset}
  ${styles}
`;

export default GlobalStyles;
