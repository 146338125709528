import { initializeApp, getApps } from "firebase/app";
import {
  browserLocalPersistence,
  getAuth,
  setPersistence
} from "firebase/auth";
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getCookie } from "cookies-next";
import { errorLogger } from "../utils/errorLog";
import { post } from "../api";
import mixpanel from "mixpanel-browser";

const firebaseConfig = {
  apiKey: process.env.firebaseApiKey,
  projectId: process.env.firebaseProjectId,
  authDomain: process.env.firebaseAuthDomain,
  appId: process.env.firebaseAppId
};

export const app =
  getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];
export const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence);

export const analytics =
  typeof window === "undefined" ? null : getAnalytics(app);

const perf = typeof window === "undefined" ? null : getPerformance(app);

/**
 * Function to log anlytics data
 *
 * //logAnalytics({ eventName: "user_event", name: "generation_success" });
 */
export function logAnalytics(payload: {
  eventName: string;
  source?: string;
  errorCode?: string;
  templateId?: number;
  time?: number;
  tagname?: string;
  tagname_v2?: string;
  item_id?: string;
  item_name?: string;
  value?: number;
  styleId?: number;
  debug_mode?: boolean;
  currency?: string;
  uid?: string;
  search?: string;
  gender?: string;
  groupName?: string;
  totalDuration?: number;
  items?: {
    price: number;
    quantity: number;
    item_id: string;
    item_name: string;
  }[];
}) {
  try {
    if (process.env.NODE_ENV === "development") {
      return console.log("Event Logging with", JSON.stringify(payload));
    }

    const referral = getCookie("referral");

    if (referral === "facebook") {
      window?.fbq?.("trackCustom", `${referral}_${payload.eventName}`, payload);
    }

    (window as any).ttq?.track(payload.eventName, payload);

    // Log to Mixpanel
    const mixpanelEvents = [
      "signup_success",
      "login_success",
      "purchase",
      "generation_start",
      "generation_fail"
    ];
    if (mixpanelEvents.includes(payload.eventName)) {
      if (payload.time) {
        payload.totalDuration = payload.time;
        delete payload.time;
        mixpanel.track(payload.eventName, payload);
      } else {
        mixpanel.track(payload.eventName, payload);
      }
    }

    //log to GA
    analytics &&
      logEvent(analytics, payload.eventName, {
        ...{ ...payload, debug_mode: false },
        ...(!referral && {
          adsSource: referral
        })
      });

    //log to backend as well.
    post("/hyper-booth/admin/log-event", { log: JSON.stringify(payload) });

    // log to mailchimp
    post("/hyper-booth/send-mailchimp-event", {
      event_name: payload.eventName,
      additional_params: payload
    });
  } catch (e) {
    errorLogger("Failed to log anlytics event");
  }
}

/**
 * Set USer ID in GA
 */
export function setUserIdGA(uid: string) {
  analytics && setUserId(analytics, uid);
}

/**
 * Log GA UTM links.
 * LogUTM( "james", "KOL" )
 */
export function LogUTM(campaign_source: string, campaign_medium: string) {
  try {
    analytics &&
      logEvent(analytics, "utm_click", { campaign_source, campaign_medium });
  } catch (e) {
    errorLogger("Failed to log UTM anlytics event");
  }
}
