import type { ReactElement, ReactNode } from "react";
import type { NextPage } from "next";
import { AppProps } from "next/app";

import { Roboto } from "next/font/google";

import { ThemeProvider } from "styled-components";

import { AuthProvider, WithAuth } from "@shared-lib/auth";

import "@shared-lib/styles/variables.css";
import "@shared-lib/styles/utils.scss";
import GlobalStyles from "../styles/GlobalStyles";
import theme from "@shared-lib/styles/theme";
import { AppStateProvider } from "@components/AppStoreProvider/AppStateProvider";
import { HeadMetaTags } from "@components/Layout/HeadMetaTags";
import { Spinner } from "@ui/Spinner";
import Script from "next/script";
import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.mixPanelToken || "", {
  track_pageview: true,
  persistence: "localStorage",
  ignore_dnt: true,
  api_host: "https://proxy.hyperbooth.ai"
});

// If loading a variable font, you don't need to specify the font weight
export const inter = Roboto({
  weight: ["100", "500", "400", "700"],
  subsets: ["latin"]
});

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
  requireLogin?: boolean;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const requireLogin = Component.requireLogin || false;
  const isLogin = pageProps.isLogin;

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider isLogin={isLogin}>
        <AppStateProvider>
          <GlobalStyles />
          <main className={inter.className}>
            <Script
              id="fb-pixel"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
                  !function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://proxy.hyperbooth.ai/fb/en_US/fbevents.js');
                  fbq('init', ${process.env.metaPixelId});
                `
              }}
            />
            <Script
              id="tiktok-pixel"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
                !function (w, d, t) {
                  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
            )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
                
                  ttq.load('${process.env.tiktokPixelId}');
                }(window, document, 'ttq');
                `
              }}
            />

            {isLogin || requireLogin ? (
              <>
                <HeadMetaTags />
                <WithAuth
                  loading={
                    <div
                      style={{
                        width: "100svw",
                        height: "100svh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <Spinner isLoading />
                    </div>
                  }
                >
                  <Component {...pageProps} />
                </WithAuth>
              </>
            ) : (
              <Component {...pageProps} />
            )}
          </main>
        </AppStateProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
