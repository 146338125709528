export enum FILENAME {
  BASE_IMAGE = "base_image",

  //For Segmenting //
  /** source image of segment */
  SEGMENT_BASEIMAGE = "SEGMENT_BASEIMAGE",
  /** black and white image of segment*/
  SEGMENT_MASKIMAGE = "SEGMENT_MASKIMAGE",
  /** segment image with transparency*/
  SEGMENT_IMAGE = "SEGMENT_IMAGE",

  /** base image with segment combined*/
  BASEIMAGE_SEGMENT_COMBINED = "BASEIMAGE_SEGMENT_COMBINED",
  /** base image with segment black and white mask*/
  BASEIMAGE_MASK_COMBINED = "BASEIMAGE_MASK_COMBINED"
}

/**
 * @todo store blob URL instead of base 64 string.
 */
const files = new Map<string, string>();

export function addFile(name: FILENAME, file: string) {
  files.set(name, file);
}

export function getFile(name: FILENAME | undefined): string | undefined {
  return files.get(name || "");
}
