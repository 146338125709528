import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useCallback
} from "react";
import { useRouter } from "next/router";
import { fetchUser } from "./firebase";
import { get, post } from "../api";
import { GetSurveyResponse } from "../types/common.types";
import { analytics, auth, logAnalytics } from "../misc/firebaseClient";
import { deleteCookie, getCookie, hasCookie, setCookie } from "cookies-next";
import { setUserId, setUserProperties } from "firebase/analytics";
import { getABGroupName } from "./utils";
import mixpanel from "mixpanel-browser";

export type Announcement = {
  announcement_id: number;
  cta: {
    label: string;
    link: string;
  };
  description: string;
  title: string;
};

export type Plan = {
  product: { id: number; name: string };
  balance: number;
  status?: number;
  renewalDate: string | null;
  creditsExpiryDate: string | null;
};

export type User = {
  uid: string;
  email: string;
  credits: number;
  surveyDone: boolean;
  hasAccessCode: boolean;
  emailVerified: boolean;
  productName: "Free" | "Basic" | "Standard" | "Pro";
  productId: number | null;
  isPromotionUser: boolean;
  announcements: Announcement[];
};

export type UserProfile = {
  plan: "Free" | "Basic" | "Standard" | "Pro";
  credits: number;
  image: string;
  billingDate: string;
  name: string;
  productId: number | null;
  announcements: Announcement[];
};

type PromoUserInfo = {
  isPromotionUser: boolean;
  freeRemainNum: number;
};

// provider ID mapping
const providerIds = new Map([
  ["password", "email"],
  ["google.com", "google"],
  ["facebook.com", "facebook"],
  ["discord.com", "discord"]
]);

const AuthContext = createContext<{
  isLogin?: boolean;
  user?: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null | undefined>>;
}>({ user: undefined, setUser() {} });

export const AuthProvider: React.FC<{
  isLogin?: boolean;
  children: React.ReactNode;
}> = (props) => {
  // Initial state : user = undefined
  // Guest         : user = null
  // Logged In     : user != null && user != undefined
  const [user, setUser] = useState<User | null>();
  const router = useRouter();

  useEffect(() => {
    async function check() {
      const authUser = await fetchUser();

      if (authUser && authUser.email && authUser.emailVerified) {
        const [profile, checkUser, promotionInfo] = await Promise.all([
          get<UserProfile>("/hyper-booth/me"),
          get<GetSurveyResponse>("/hyper-booth/checkUserStatus"),
          get<PromoUserInfo>("/hyper-booth/promotion-user-info")
        ]).catch();

        setUser({
          uid: authUser.uid,
          email: authUser.email,
          credits: profile.credits,
          surveyDone: !!checkUser?.surveyDone,
          hasAccessCode: !!checkUser?.inviteCodeActive,
          emailVerified: authUser?.emailVerified,
          productName: profile.plan,
          productId: profile.productId,
          isPromotionUser: promotionInfo.isPromotionUser,
          announcements: profile.announcements
        });

        mixpanel.people.set({
          plan: profile?.plan
        });

        //If KOL cookie is detected we will log to backend,
        if (hasCookie("KOL_LINK")) {
          const referer = getCookie("KOL_LINK") || "";
          //log to backend.
          console.log("Logging KOL Referal", referer);
          //Remove cookie.
          deleteCookie("KOL_LINK");
          await post<number>(`/hyper-booth/record-inviter/${referer}`);
        }
      } else {
        setUser(null);
      }
      // log to mailchimp the page view
      post("/hyper-booth/send-mailchimp-event", {
        event_name: "page_view",
        additional_params: { pathname: window.location.pathname }
      });
    }
    check();
  }, [router.pathname]);

  useEffect(() => {
    // log the loggin success
    async function logUser() {
      const authUser = await fetchUser();

      if (authUser && analytics) {
        setUserId(analytics, authUser.uid);
        mixpanel.identify(authUser.uid);
      }
      if (authUser && authUser.emailVerified) {
        if (analytics)
          setUserProperties(analytics, {
            groupName: getABGroupName(authUser.uid, "generation_1"),
            user_email: authUser?.email || ""
          });

        mixpanel.people.set({
          uid: authUser?.uid,
          name: authUser?.displayName || "",
          email: authUser?.email || "",
          creationTime: authUser?.metadata?.creationTime,
          lastSignInTime: authUser?.metadata?.lastSignInTime
        });

        logAnalytics({
          eventName: "login_success",
          uid: authUser.uid,
          source: providerIds.get(authUser?.providerData[0]?.providerId)
        });
      }
    }
    logUser();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, isLogin: props.isLogin }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const { user, setUser, isLogin } = useContext(AuthContext);
  const refreshCredit = useCallback(async () => {
    const balance = await get<number>("/hyper-booth/userAsset");
    setUser((user) => {
      if (user) {
        mixpanel.people.set({ credits: balance || 0 });
        return { ...user, credits: balance };
      } else {
        return user;
      }
    });
  }, []);

  const refreshAnnouncement = useCallback(async () => {
    const profile = await get<UserProfile>("/hyper-booth/me");

    setUser((user) => {
      if (user) {
        return { ...user, announcements: profile.announcements };
      } else {
        return user;
      }
    });
  }, []);

  return { user, refreshCredit, refreshAnnouncement, isLogin };
}

export function WithAuth({
  children,
  loading
}: {
  children: React.ReactNode;
  loading?: React.ReactNode;
}) {
  const router = useRouter();
  const { user, ...rest } = useAuth();

  useEffect(() => {
    if (user === null) {
      router.push(
        "/login?redirect_url=" + encodeURIComponent(window.location.href)
      );
    } else if (user?.uid && !user?.emailVerified) {
      router.push(
        `/login/not-verified?email=${encodeURIComponent(user?.email)}`
      );
    }
  }, [router, user]);

  if (!user) {
    return loading;
  }

  return children;
}
