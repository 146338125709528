type ABTestItem = {
  groupName: string;
  ratio: Record<string, number>;
};

export const abTestConfig: ABTestItem[] = [
  {
    groupName: "generation_1", // show and hide the showcase images in personalize page
    ratio: {
      showcase: 0,
      control: 100
    }
  }
];
