import React from "react";
import cn from "classnames";
import { Spinner as IconSpinner } from "../Icons/Spinner";
import styles from "./Spinner.module.scss";

interface Props {
  size?: number;
  isLoading?: boolean;
  text?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  contentClassName?: string;
}

export const Spinner: React.FC<Props> = ({
  size = 28,
  children,
  isLoading = false,
  text,
  className,
  contentClassName
}) => {
  if (children) {
    return (
      <div className={cn(styles.spinnerWrapper, className)}>
        {isLoading && (
          <div className={styles.spinnerContainer}>
            <IconSpinner className={styles.spinner} size={size} />
            {text && <div>{text}</div>}
          </div>
        )}
        <div
          className={cn(
            styles.childrenContainer,
            contentClassName,
            isLoading && styles.loading
          )}
        >
          {children}
        </div>
      </div>
    );
  } else {
    return isLoading ? (
      <div className={cn(styles.spinnerWrapper, className)}>
        <IconSpinner className={styles.spinner} size={size} />
      </div>
    ) : null;
  }
};
