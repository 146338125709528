export enum ImageNames {
  /** Paint cursor */
  PAINT_CURSOR = "paintCursor",
  /** Painted selection with selection brush. */
  PAINTSELECTION = "PaintSelection",
  /** Represnets the base image. */
  BASEIMAGE = "BaseImage",
  /**  a final combined image segment, used in add image workflow also refers o the transparent segment in main canvas */
  SEGMENT = "segment",
  /**  individual selecteable segment slices. */
  SEGMENT_SLICE = "segment_slice",
  TEST = "testImage",
  /**  The default setting when images are loaded, with loadImage utility */
  NONE = "NONE",
  /** Final user selection */
  SELECTION = "selection"
}

/**
 * User authentication state.
 */
export interface AppState {
  app: UserState;
}

export interface UserState {
  symbols: string[];
  playvideo: boolean;
  auth: Auth | null;
}

export interface Auth {
  accessToken: string;
  displayName: string;
  email: string;
  emailVerified: boolean;
  uid: string;
  refreshToken: string;
  surveyDone?: boolean;
  inviteCodeActive?: boolean;
  refreshTime?: string;
}

export interface GetSurveyResponse {
  inviteCodeActive: boolean;
  surveyDone: boolean;
}
