import Head from "next/head";
import React from "react";

const SITE_TITLE = "Best choice of AI image generator";
const SITE_DESC = "Create stunning AI photos instantly with just one selfie";

type Props = {
  title?: string;
  description?: string;
  bannerImage?: string;
  children?: React.ReactNode;
};

export function HeadMetaTags({
  title = `HyperBooth - ${SITE_TITLE}`,
  description = SITE_DESC,
  bannerImage = "https://www.hyperbooth.ai/images/social-media-sharing-banner.jpg",
  children
}: Props) {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={bannerImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@HyperBooth_AI" />
      <meta name="twitter:creator" content="@HyperBooth_AI" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={bannerImage} />
      {children}
    </Head>
  );
}
