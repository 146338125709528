import { logAnalytics } from "../misc/firebaseClient";
import { getRedirectUrl } from "../utils/url";
import { abTestConfig } from "../config/abtest";
import { deleteCookie, setCookie } from "cookies-next";

export function gotoDiscordLogin() {
  const clientId = process.env.discordClientId;
  if (!clientId) return;
  const params = new URLSearchParams({
    client_id: clientId,
    response_type: "token",
    redirect_uri: window.location.origin + "/auth/callback",
    scope: "identify email",
    state: new URLSearchParams({
      redirect_url: getRedirectUrl(window.location.search) || "/styles"
    }).toString()
  });

  const url = new URL("https://discord.com/api/oauth2/authorize");
  url.search = params.toString();
  window.location.href = url.toString();
}

export function setLoginToken(token: string) {
  const ONE_YEAR = 365 * 24 * 60 * 60;
  setCookie("loginToken", token, { maxAge: ONE_YEAR });
}

export function deleteLoginToken() {
  deleteCookie("loginToken");
}

export const convertHashToNum: (str: string) => number = (str: string) => {
  let finalNumber = "";

  for (let i = 0; i < str.length; i++) {
    finalNumber += str.charCodeAt(i);
  }

  return Number(finalNumber);
};

export const getABGroupName = (uid: string, groupName: string) => {
  const { ratio } =
    abTestConfig.find((item) => item.groupName === groupName) || {};
  if (!ratio) return;

  // Validate that the sum of ratios is equal to 100
  const totalRatio = Object.values(ratio).reduce((acc, val) => acc + val, 0);
  if (totalRatio !== 100) {
    throw new Error("The sum of ratios for the group must be equal to 10");
  }

  const numUID = convertHashToNum(uid) % 100;

  let lowerBound = 0;
  let upperBound = 0;

  for (let key in ratio) {
    upperBound += ratio[key];
    if (numUID >= lowerBound && numUID < upperBound) {
      return `${groupName}_${key}`;
    }
    lowerBound = upperBound;
  }
};
