/**
 * @todo create a theme type for type safety.
 */
export const theme = {
  colors: {
    //new colors
    darkGrey: "#292929",
    midGrey: "#2a2a2a",
    lightGrey: "#545454",

    fontLightGrey: "#b0b0b0",

    secondaryGrey: "#7C7C7C",
    orange: "#e0225b",
    blue: "#0A6CFF",
    redError: "#FF0000",

    menuMaroon: "#94073B",

    grey1: "#3F3F3F",
    grey2: "rgba(255, 255, 255, 0.20)",
    grey10: "rgba(255, 255, 255, 0.10)",

    white: "#FFFFFF",
    white50: "rgba(255, 255, 255, 0.5)",
    white20: "rgba(255, 255, 255, 0.2)",
    white10: "rgba(255, 255, 255, 0.1)"
  },
  size: {
    padding1: "1rem",
    padding2: "2rem",
    padding3: "3rem",
    padding4: "4rem",
    semiBold: { l: "2.8rem", m: "2.4rem", s: "2.0rem" },
    regular: { l: "1.8rem", m: "1.6rem", s: "1.4rem" },
    fontWeight: { thin: "400", regular: "500", semiBold: "600" }
  },
  depth: {
    low: 0,
    mid: 10,
    high: 100,
    highest: 1000
  },
  screen: {
    sm: "375px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    xxl: "1920px"
  },
  animations: {
    slowTransition: {
      duration: 0.5,
      delay: 0.1,
      ease: [0, 0.71, 0.2, 1.01]
    }
  }
};

//font weights
//thin, regular, semibold
//400, 500, 600
export default theme;
