import {
  onAuthStateChanged,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail as sendResetEmail,
  sendEmailVerification,
  type User,
  getAdditionalUserInfo,
  applyActionCode
} from "firebase/auth";
import { deleteCookie } from "cookies-next";
import { auth, logAnalytics } from "../misc/firebaseClient";
import { FirebaseError } from "firebase/app";
import { delay } from "../utils";
import { deleteLoginToken, setLoginToken } from "./utils";

export async function loginWithCustomToken(customToken: string) {
  const userData = await signInWithCustomToken(auth, customToken);
  setLoginToken(await userData.user.getIdToken());
  return userData;
}

export async function loginWithEmail(email: string, password: string) {
  const userData = await signInWithEmailAndPassword(auth, email, password);
  setLoginToken(await userData.user.getIdToken());
  return userData;
}

export async function loginWithApple() {
  const appleProvider = new OAuthProvider("apple.com");
  try {
    appleProvider.addScope("email");
    appleProvider.addScope("name");
    const login = await signInWithPopup(auth, appleProvider);
    setLoginToken(await login.user.getIdToken());
    const details = getAdditionalUserInfo(login);
    if (details?.isNewUser) {
      logAnalytics({
        eventName: "signup_success",
        source: "apple"
      });
      await delay(300); // delay for 300ms to allow sending out event
    }
  } catch (e) {
    if (e instanceof FirebaseError) {
      const isExist =
        e.customData &&
        typeof e.customData.email === "string" &&
        (await checkEmailExist(e.customData.email));
      logAnalytics({
        eventName: isExist ? "login_fail" : "signup_fail",
        errorCode: e.code,
        source: "apple"
      });
    }
    throw e;
  }
}

export async function loginWithFacebook() {
  const facebookProvider = new FacebookAuthProvider();
  try {
    const login = await signInWithPopup(auth, facebookProvider);
    setLoginToken(await login.user.getIdToken());
    const details = getAdditionalUserInfo(login);
    if (details?.isNewUser) {
      logAnalytics({
        eventName: "signup_success",
        source: "facebook"
      });
      await delay(300); // delay for 300ms to allow sending out event
    }
  } catch (e) {
    if (e instanceof FirebaseError) {
      const isExist =
        e.customData &&
        typeof e.customData.email === "string" &&
        (await checkEmailExist(e.customData.email));
      logAnalytics({
        eventName: isExist ? "login_fail" : "signup_fail",
        errorCode: e.code,
        source: "facebook"
      });
    }
    throw e;
  }
}

export async function loginWithGoogle() {
  const googleProvider = new GoogleAuthProvider();
  try {
    const login = await signInWithPopup(auth, googleProvider);
    setLoginToken(await login.user.getIdToken());
    const details = getAdditionalUserInfo(login);
    if (details?.isNewUser) {
      logAnalytics({
        eventName: "signup_success",
        source: "google"
      });
      await delay(300);
    }
  } catch (e) {
    if (e instanceof FirebaseError) {
      const isExist =
        e.customData &&
        typeof e.customData.email === "string" &&
        (await checkEmailExist(e.customData.email));
      logAnalytics({
        eventName: isExist ? "login_fail" : "signup_fail",
        errorCode: e.code,
        source: "google"
      });
    }
    throw e;
  }
}

export async function verifyEmailOobCode(oobCode: string) {
  try {
    await applyActionCode(auth, oobCode);
    auth.currentUser && setLoginToken(await auth.currentUser.getIdToken());
    logAnalytics({
      eventName: "signup_success",
      source: "email"
    });
  } catch (e) {
    logAnalytics({
      eventName: "signup_fail",
      errorCode: "invalid oobCode",
      source: "email"
    });
    throw e;
  }
}

export async function logout() {
  deleteLoginToken();
  await auth.signOut();
}

export async function createEmailAccount(email: string, password: string) {
  const login = await createUserWithEmailAndPassword(auth, email, password);
  const details = getAdditionalUserInfo(login);
  if (details?.isNewUser) {
    logAnalytics({
      eventName: `signup_email_notverified`,
      source: "email"
    });
    await delay(300);
  }
  auth.currentUser && (await sendEmailVerification(auth.currentUser));
}

export async function checkEmailExist(email: string) {
  const result = await fetchSignInMethodsForEmail(auth, email);
  return result.length > 0;
}

export async function sendPasswordResetEmail(email: string) {
  await sendResetEmail(auth, email);
}

export async function fetchToken(): Promise<string | null> {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      async (authUser) => {
        if (authUser) {
          const token = await authUser.getIdToken();
          setLoginToken(token);
          resolve(token);
        } else {
          resolve(null);
        }
        unsubscribe();
      },
      () => {
        reject();
        unsubscribe();
      }
    );
  });
}

export async function forceRefreshToken() {
  const currentUser = auth.currentUser;
  if (currentUser) {
    const token = await currentUser.getIdToken(true);
    setLoginToken(token);
    return token;
  } else {
    return null;
  }
}

export async function fetchUser(): Promise<User | null> {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      async (authUser) => {
        if (authUser) {
          resolve(authUser);
        } else {
          resolve(null);
        }
        unsubscribe();
      },
      () => {
        reject();
        unsubscribe();
      }
    );
  });
}
