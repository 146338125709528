/* eslint-disable react/prop-types */
import { createContext, useContext, useState } from "react";

export type AppState = {
  prompt: string | null;
  aspectRatio: string | null;
  modelStyle: "real" | "creativity" | null;
};

const AppStateContext = createContext<{
  appState: AppState | null;
  setAppState: React.Dispatch<React.SetStateAction<AppState | null>>;
}>({ appState: null, setAppState() {} });

export function useAppState() {
  const { appState, setAppState } = useContext(AppStateContext);
  return { appState, setAppState };
}

export const AppStateProvider: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  const [appState, setAppState] = useState<AppState | null>(null);

  return (
    <AppStateContext.Provider value={{ appState, setAppState }}>
      {props.children}
    </AppStateContext.Provider>
  );
};
